import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'
import breakString from '../common/break-string-remove-query.js'

import Layout from '../layouts/default/layout';

const MyAccountPage = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  useEffect(()=>{
    setData(dataJSON)    
    myAccountActive()
  }, [])

  const myAccountActive = ()=>{
    let interval = setInterval(()=>{
        if(document.querySelector('ae-login-modal')){
            clearInterval(interval)
            let myAccountComponent = document.createElement('ae-my-account')
            document.querySelector('.content-my-account').appendChild(myAccountComponent)
            document.querySelector('ae-my-account').config = {
              excludeRoutes: [], 
            }

            const possiblesValues =  [
                "personal-data",
                "bookings",
                "credits",
                "plans",
                "sales",
                "cards",
                "password",
                "privacy",
                "dependants",
                "performance",
                "fixed-schedule"
            ]

            const routesAngulare =  {
                "personal-data":"personal-data",
                "bookings":"bookings",
                "credits":"credits",
                "plans":"subscriptions",
                "sales":"orders",
                "cards":"creditcards",
                "password":"change-password",
                "privacy":"parq-and-medical-exam",
                "dependants":"children",
                "performance":"performance",
                "fixed-schedule":"fixed-schedule"
            }

            if(dataJSON.angulare_settings.account_modules && dataJSON.angulare_settings.account_modules.length){
              possiblesValues.map(item=>{
                if(!dataJSON.angulare_settings.account_modules.filter(item2=>item2===item).length){
                  document.querySelector('ae-my-account').config.excludeRoutes.push(routesAngulare[item])
                }
                  
                return []
               
                
              })
              
            }

            if(dataJSON.angulare_settings.account_banner && dataJSON.angulare_settings.account_banner.length){
              document.querySelector('ae-my-account').config.coverImage = 'https://budibase.internal.angulare.app'+breakString(dataJSON.angulare_settings.account_banner[0].url)
              
            }
            


        }
    }, 1000)
     
  }

  
  return (
    <>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="">
          <div className="row">
            <div className="col-md-12 content-my-account">

            </div>
          </div>


        </div>
      </Layout>

    </>
  )
}

export default MyAccountPage
